import { ImTwitter, ImLinkedin2, ImGithub, ImInstagram, ImYoutube } from 'react-icons/im';
import './home.scss';
function Home(){

    return(       
        <header className="header">
            <div className="header__container">
                <img className='header__container-bracket' src={`${process.env.REACT_APP_PUBLIC_URL}assets/img/br_left.png`} alt="left_bracket"/>
                <div className="header__container-details">
                    <h1 className='header__container-title'>Kevin Vergara</h1>
                    <h2 className='header__container-subtitle'>Drummer,Mobile Web Developer</h2>
                    <div className='header__social_links'>
                        <a className='header__social_links-ico' rel="nofollow noopener noreferrer" href='https://www.linkedin.com/in/kevinfrnando/' target="_blank"><i><ImLinkedin2/></i></a>
                        <a className='header__social_links-ico' rel="nofollow noopener noreferrer" href='https://github.com/kevinfrnando' target="_blank"><i><ImGithub/></i></a>
                        <a className='header__social_links-ico' rel="nofollow noopener noreferrer" href='https://twitter.com/kevinfrnando' target="_blank"><i><ImTwitter/></i></a>
                        <a className='header__social_links-ico' rel="nofollow noopener noreferrer" href='https://www.instagram.com/kevinfrnandov/' target="_blank"><i><ImInstagram/></i></a>
                        <a className='header__social_links-ico' rel="nofollow noopener noreferrer" href='https://www.youtube.com/channel/UCYq_3vVqH6D_e6EGdhSnyqQ/about' target="_blank"><i><ImYoutube/></i></a>
                    </div>
                </div>
                <img className='header__container-bracket' src={`${process.env.REACT_APP_PUBLIC_URL}assets/img/br_right.png`} alt="right_bracket"/>
            </div>
            <img className='header__construction-image' src={`${process.env.REACT_APP_PUBLIC_URL}assets/img/under-construction.png`}/>
        </header>
    )
}

export default Home;