import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/Home/Home';
import Contact from './components/Contact/Contact';
import { createContext, useEffect, useState } from 'react';

export const webData = createContext({});

function App() {

  const [ data, setData ] = useState( {} );

  useEffect( ()=>{
    setData({
      name : "Kevin Vergara"
    })
  },[]);


  return (
    <Router>
      <webData.Provider value={{ data }}>
        <Routes>
          <Route path='/' element={ <Home/> } />
          {/* <Route path='/profile' element={ <Profile/> }/>
          <Route path='/formation' element={ <Formation/> }/>
          <Route path='/aboutme' element={ <AboutMe/> }/>
          <Route path='/covers' element={ <Covers/> }/> */}
          <Route path='/contact' element={ <Contact/> }/>
        </Routes>
      </webData.Provider>  
    </Router>
    // <Index/>
  );
}
export default App;
