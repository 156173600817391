import { useEffect, useState } from 'react';
import { MdOutlinePersonAddAlt, MdLocationOn, MdEmail, MdCall} from 'react-icons/md';
import { ImTwitter, ImLinkedin2, ImGithub, ImInstagram, ImYoutube } from 'react-icons/im';
import Message from '../Message/Message';
import './contact.scss';

function Contact(){


    const [ viewForm, setViewForm ] = useState( false ); 

    return (
        <section id="contact">
            <div className="container" >
                <div className="contact">
                    <div>
                        <h2>CONTACT</h2>
                        <p className="title-description">Contact me</p>
                    </div>
                    {
                        viewForm
                        ?
                        <div className="contact__message">
                                <Message setViewForm={ setViewForm }/>                         
                            </div>
                        :
                        <div className="contact__items">
                            <div className="contact__items-row">
                                <i className="contact__items-icos">
                                    <MdLocationOn/>
                                </i>
                                <div className="contact__items-details">
                                    <h3>My Direction</h3>
                                    <p className='p-detail'>Guayaquil, Ecuador</p>
                                </div>
                            </div>
                            <div className="contact__items-row">
                                <i className="contact__items-icos">
                                    <MdOutlinePersonAddAlt/>
                                </i>
                                <div className="contact__items-details">
                                    <h3>Social Network</h3>
                                    <div className='contact__items-links'>
                                        <a className='contact__items-links-icons' rel="nofollow noopener noreferrer" href='https://www.linkedin.com/in/kevinfrnando/' target="_blank"><i><ImLinkedin2/></i></a>
                                        <a className='contact__items-links-icons' rel="nofollow noopener noreferrer" href='https://github.com/kevinfrnando' target="_blank"><i><ImGithub/></i></a>
                                        <a className='contact__items-links-icons' rel="nofollow noopener noreferrer" href='https://twitter.com/kevinfrnando' target="_blank"><i><ImTwitter/></i></a>
                                        <a className='contact__items-links-icons' rel="nofollow noopener noreferrer" href='https://www.instagram.com/kevinfrnandov/' target="_blank"><i><ImInstagram/></i></a>
                                        <a className='contact__items-links-icons' rel="nofollow noopener noreferrer" href='https://www.youtube.com/channel/UCYq_3vVqH6D_e6EGdhSnyqQ/about' target="_blank"><i><ImYoutube/></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="contact__items-row">
                                <i className="contact__items-icos"> 
                                    <MdEmail/>
                                </i>
                                <div className="contact__items-details">
                                    <h3>Write me a message</h3>
                                    <p className='p-detail'>info@kevinvergara</p>
                                </div>
                                
                            </div>
                            <div className="contact__items-row">
                                <i className="contact__items-icos"><MdCall/></i>
                                <div className="contact__items-details">
                                    <h3>Call me</h3>
                                    <p className='p-detail'>+593 985 219 251</p>
                                </div>
                            </div>
                            <div className="contact__items-row">
                                <button                             
                                    className="success-button contact__button-send" 
                                    onClick={ () => setViewForm( true ) }>
                                    Do you want to write an email?
                                </button>
                            </div>
                        </div>

                    }
                </div>
            </div>

        </section>
    )
}

export default Contact;