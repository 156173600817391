import { useState } from 'react';
import './Message.scss';

const emptyForm = {
    name : null,
    email : null,
    subject : null,
    message : null,
}
function Message( { setViewForm } ){
    const [ form, setForm ] = useState(emptyForm);
    const [ validForm, setValidForm ] = useState( false );


    const formDataChanged = ( e ) => {
        const { name, value } = e.target;
        const newData = { ...form, [name] : value }
        setForm( newData );
        checkForm( newData );
    }

    const checkForm = ( obj ) => {
        const valid = Object.values(obj).every(
            (value) => typeof value === 'string' && value.trim() !== ''
        );
        setValidForm( valid );
    }
    return(
        <div className="message">
            <div className="message__controls">
                <input className="message__controls-input" onChange={ formDataChanged } id="name" name='name' type="text" placeholder="Your Name"></input>
            </div>
            <div className="message__controls">
                <input className="message__controls-input" onChange={ formDataChanged } id="email" name='email' type="email" placeholder="Your Email"></input>
            </div>
            <div className="message__controls">
                <input className="message__controls-input" onChange={ formDataChanged } id="subject" name='subject' type="text" placeholder="The subject"></input>
            </div>
            <div className="message__controls">
                <textarea className="message__controls-input message__controls-text-area" onChange={ formDataChanged } id="message" name='message' placeholder="Message">
                </textarea>
            </div>
            <div className="message__controls">
                <button
                    className="cancel-button" 
                    onClick={ () => setViewForm( false ) }>
                    Cancel
                </button>
            </div>
            <div className="message__controls">
                <button className={ validForm ? "success-button" : "disabled-button"}>Send :D</button>
            </div>

        </div>
    )
}



export default Message;